import { Image, styled, Text, useSx, View } from 'dripsy'
import { useCalculateFontSize } from 'app/redux/hooks/use-calculate-font-size'
import { Button } from 'app/components/button'
import { Icon } from 'app/components/icon/icon'
import { useRouter } from 'solito/router'

const TitleText = styled(Text)({
  fontFamily: 'Merriweather-Bold',
})

const TextParagraph = styled(Text)({
  fontFamily: 'Montserrat',
})

const Aboutus = () => {
  const sx = useSx()
  const getFontSize = useCalculateFontSize()
  const { push } = useRouter()

  return (
    <View
      style={sx({
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        padding: [0, '5%'],
        backgroundColor: '#F8FBFA',
      })}
    >
      <View
        style={sx({
          flex: 1,
          display: ['none', 'flex'],
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <Image
          resizeMode={'contain'}
          style={[
            sx({
              minHeight: [300, 400, 500],
              width: ['100%'],
              height: ['100%'],
            }),
          ]}
          source={require('../../assets/aboutUs.webp')}
          resizeMethod={'scale'}
          alt="aboutUs"
        />
      </View>

      <View
        style={sx({
          flex: 3,
          flexDirection: 'column',
          justifyContent: 'center',
          paddingLeft: '5%',
        })}
      >
        <TitleText
          style={sx({
            fontSize: getFontSize(70, 20),
            fontWeight: '700',
            textAlign: 'left',
            color: '$primary',
          })}
        >
          About Us
        </TitleText>
        <View style={sx({ height: [0, 20] })} />
        <TextParagraph
          style={sx({
            fontSize: getFontSize(50, 14),
            fontWeight: '500',
            textAlign: 'left',
            lineHeight: [24, 28, '34.24px'],
            letterSpacing: '-0.02em',
          })}
        >
          Home improvement should be exciting, not overwhelming. At Gennoctua,
          we make DIY home design effortless with cutting-edge AI tools that
          help you plan, visualize, and execute your projects with ease. Whether
          you’re arranging furniture or assembling a complex setup, we guide you
          every step of the way.
        </TextParagraph>
        <View style={sx({ height: [10, 20] })} />
        <Button
          style={sx({
            height: 40,
            maxWidth: [250, 300],
            backgroundColor: '#2D1D1D',
            padding: 10,
          })}
          textStyle={{
            fontSize: 14,
            fontFamily: 'Montserrat',
          }}
          onPress={() => {
            push('/account/signup')
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                color: '#FFF',
                marginRight: 20,
              }}
            >
              Start Your DIY Journey Today
            </Text>
            <Icon
              name={'arrow'}
              fill={['#FFF']}
              style={{
                transform: [{ rotate: '90deg' }],
              }}
            />
          </View>
        </Button>
      </View>
    </View>
  )
}

export default Aboutus
