import { Image, styled, Text, useSx, View } from 'dripsy'
import { useCalculateFontSize } from 'app/redux/hooks/use-calculate-font-size'
import { Button } from 'app/components/button'
import { Icon } from 'app/components/icon/icon'
import { useRouter } from 'solito/router'
import { useState } from 'react'
import { Platform } from 'react-native'

const TitleText = styled(Text)({
  fontFamily: 'Merriweather-Bold',
})

const TextParagraph = styled(Text)({
  fontFamily: 'Montserrat',
})

const ExploreOurFeatures = () => {
  const sx = useSx()
  const { push } = useRouter()

  const getFontSize = useCalculateFontSize()
  const list = [
    {
      title: 'Empowerment',
      icon: require('../../assets/landingPage/protest.webp'),
      desc: 'Snap a picture of your space, and our AI will show you exactly where your furniture fits, making it easy to create the perfect layout without lifting a finger.',
    },
    {
      title: 'Simplicity',
      icon: require('../../assets/landingPage/like.webp'),
      desc: 'Our AI simplifies the most complicated parts of home design, from layout planning to furniture assembly, giving you more time to enjoy your home.',
    },
    {
      title: 'Customization',
      icon: require('../../assets/landingPage/edit.webp'),
      desc: 'No cookie-cutter solutions here. Every space is unique, and our tools adapt to your vision, helping you create a home that reflects your personality.',
    },
  ]

  return (
    <View
      style={sx({
        width: '100%',
        minHeight: ['50vh', '50vh', '60vh'],
        flexDirection: 'column',
        paddingX: ['5%', '5%', '10%'],
        backgroundColor: '#F8FBFA',
        //    paddingTop:'5%'
      })}
    >
      <View
        style={sx({
          width: '100%',
          minHeight: 46,
        })}
      >
        <TitleText
          style={sx({
            fontSize: getFontSize(100, 20),
            fontWeight: '700',
            // textAlign: ['left','right','right'],
            textAlign: 'right',
            alignSelf: 'flex-end',
            lineHeight: ['30px', '46px'],
            letterSpacing: '-0.02em',
            color: '$primary',
            maxWidth: 500,
          })}
        >
          Our Values—What We Bring to You
        </TitleText>
      </View>
      <View
        style={sx({
          flexDirection: ['column', 'column', 'row'],
          flex: 1,
          height: 'calc(100% - 46px)',
        })}
      >
        {list.map((item, index) => {
          return <Card item={item} index={index} key={index} />
        })}
      </View>
      <View
        style={sx({
          width: '100%',
          justifyContent: 'center',
          alignItems: ['flex-start', 'flex-start', 'center'],
        })}
      >
        <TitleText
          style={sx({
            fontSize: [getFontSize(28, 14), getFontSize(70, 20)],
            fontWeight: '700',
            textAlign: 'left',
            lineHeight: '46px',
            letterSpacing: '-0.02em',
          })}
        >
          Discover How Easy DIY Can Be
        </TitleText>
        <View
          style={sx({
            height: [10, 20],
          })}
        ></View>
        <Button
          style={sx({
            height: 40,
            maxWidth: 350,
            minWidth: [200, 250],
            backgroundColor: '#2D1D1D',
            padding: 10,
          })}
          textStyle={{
            fontSize: 14,
            fontFamily: 'Montserrat',
          }}
          onPress={() => {
            push('/account/signup')
          }}
          endIcon={
            <Icon
              name={'arrow'}
              fill={['#FFF']}
              style={{
                transform: [{ rotate: '90deg' }],
              }}
            />
          }
        >
          Explore Our Features
        </Button>
      </View>
    </View>
  )
}
export default ExploreOurFeatures

const Card = ({ item, index }) => {
  const [isHovered, setIsHovered] = useState(false)
  const sx = useSx()
  const getFontSize = useCalculateFontSize()
  return (
    <View
      {...(Platform.OS === 'web'
        ? {
            onMouseEnter: () => setIsHovered(true),
            onMouseLeave: () => setIsHovered(false),
          }
        : {})}
      key={`${index}-index-data`}
      style={[
        sx({
          height: [185, 200, 300],
          marginTop: [0, 0, index === 0 ? 0 : index === 1 ? '10%' : '20%'],
          width: ['80%', '100%', '33.33%'],
          marginLeft: index === 1 ? ['20%', '0', '0'] : 0,
          border: index === 1 ? ['1px solid #CE7335', '0', '0'] : 0,
          padding: index === 1 ? [10, 0, '5%'] : [0, 0, '5%'],
          flexDirection: ['column'],
          // backgroundColor: index % 2 ? '#FFF' : '#F8FBFA',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease', // Smooth transition
          backgroundColor: isHovered ? '#FFF' : '#F8FBFA',
        }),
      ]}
    >
      <View
        style={sx({
          flexDirection: 'row',
          alignItems: 'center',
        })}
      >
        <Image
          resizeMode={'contain'}
          style={[
            sx({
              width: ['30px', '40px'],
              height: ['30px', '40px'],
              objectFit: 'contain',
            }),
          ]}
          source={item.icon}
          resizeMethod={'scale'}
          alt="aboutUs"
        />
        <TextParagraph
          style={sx({
            fontSize: 24,
            fontWeight: '600',
            textAlign: 'left',
            color: '#030605',
            marginLeft: 5,
            paddingTop: -5,
          })}
        >
          {item.title}
        </TextParagraph>
      </View>
      <TextParagraph
        style={sx({
          fontSize: 16,
          fontWeight: '500',
          textAlign: 'left',
          color: '#5C5E5E',
        })}
      >
        {item.desc}
      </TextParagraph>
    </View>
  )
}
